import React, {useEffect} from 'react'
import AOS from 'aos'
import "aos/dist/aos.css"

const Info = () => {
  useEffect(()=>{
    AOS.init({duration: 900});
  }, []);
  
  return (
    <div className="about__info grid">
        <div data-aos="flip-up" className='about__box'>
            <i className='bx bx-award about__icon'></i>
            <h3 className='about__title'>Experience </h3>
            <span className='about__subtitle'>4+ Years Working</span>
        </div>
        <div data-aos="flip-up" className='about__box'>
            <i className='bx bx-briefcase about__icon'></i>
            <h3 className='about__title'>Completed</h3>
            <span className='about__subtitle'> 7+ Projects</span>
        </div>
        <div data-aos="flip-up" className='about__box'>
            <i className='bx bx-support about__icon'></i>
            <h3 className='about__title'>Support</h3>
            <span className='about__subtitle'>Online 24/7</span>
        </div>
       
    </div>
  )
}

export default Info